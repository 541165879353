import React from "react";
import { Typography, Menu, MenuItem, useTheme } from '@mui/material';
import { List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Stack } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import SearchInput from "components/search/searchinput";

const Samples = [
    {
        Field_1: require("assets/images/icons/laptop.png"),
        Field_2: "Laptop",
        Field_3: "HP Pavilion 14, 12th Gen Intel Core i5-1235U",
        Field_4: 12.50,
        Field_5: "Kilogram",
        Field_6: "NotStarted"
    },
    {
        Field_1: null,
        Field_2: "Bata",
        Field_3: "Mens Boss-Slick Formal Shoes",
        Field_4: 1.10,
        Field_5: "Kilogram",
        Field_6: "Started"
    },
    {
        Field_1: null,
        Field_2: "Fashion",
        Field_3: "FUNDAY FASHION Cotton Half Sleeve Printed Oversized T-Shirt",
        Field_4: 250,
        Field_5: "Gram",
        Field_6: "Pending"
    },
    {
        Field_1: require("assets/images/icons/camera.png"),
        Field_2: "Security Camera",
        Field_3: "Wipro Smart Wireless Security Camera",
        Field_4: 980,
        Field_5: "Gram",
        Field_6: "Completed"
    },
    {
        Field_1: null,
        Field_2: "Echo Dot (5th Gen)",
        Field_3: "Echo Dot (5th Gen) | Smart speaker with Bigger sound, Motion Detection, Temperature Sensor, Alexa and Bluetooth",
        Field_4: 1.69,
        Field_5: "Kilogram",
        Field_6: "NotStarted"
    }
];

const MenuItemList = (props) => {
    const { anchorEl, fieldId, onMenuClosed, selectedProperties, selectedColumns, seqNumber } = props;
    const open = Boolean(anchorEl);
    const [menuItems, setMenuItems] = React.useState([]);
    const theme = useTheme();

    const handleClose = (e, index) => {
        e.preventDefault();
        onMenuClosed({ name: fieldId, value: menuItems[index], seqNumber, index });
    };

    const IsMenuSelected = (e, v) => {
        if (selectedColumns && selectedColumns[e] && selectedColumns[e].DisplayName === v) return true;
        return false;
    }

    React.useEffect(() => {
        let mnuItems = [];
        if (fieldId === "Field_1") {
            mnuItems = selectedProperties.filter(z => z.HasStream).map(x => x.DisplayName);
        } else {
            mnuItems = selectedProperties.filter(z => !z.HasStream).map(x => x.DisplayName);
        }
        let selectedItems = Object.values(selectedColumns);
        if (selectedItems && selectedItems.length > 0) {
            selectedItems = selectedItems.map(x => x.DisplayName);
            mnuItems = mnuItems.filter(x => selectedItems.indexOf(x) === -1);
        }

        setMenuItems(mnuItems);
    }, [selectedProperties, fieldId, selectedColumns]);

    if (!selectedProperties || selectedProperties.length === 0) {
        onMenuClosed();
        return;
    }

    return (
        <Menu
            id="basic-menu"
            open={open}
            onClose={() => onMenuClosed()}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            value={"InvoiceShipment/ShipmentTrackingNumber"}
            anchorEl={anchorEl}
        >
            {menuItems && menuItems.map((x, index) => (
                <MenuItem key={index} value={x} onClick={(e) => handleClose(e, index)} sx={{ backgroundColor: IsMenuSelected(fieldId, x) ? "rgba(25, 118, 210, 0.08)" : "inherit" }}>
                    {x}
                </MenuItem>
            ))}
            <MenuItem value={"NONE"} onClick={(e) => handleClose(e, -1)} sx={{ color: theme.palette.error.main }}>
                Remove Field
            </MenuItem>
        </Menu>
    );
}

const RenderMenuItem = (e) => {

    const { index, id, value, variant, onClick, count } = e;

    return (
        <Typography id={id} name={id} variant={variant} component="span" sx={{
            display: 'block', cursor: count > 0 && index === 0 ? "pointer" : "default",
            '&:hover': {
                border: count > 0 && index === 0 && "1px dashed blue"
            },
            marginBottom: "2px"
        }} onClick={(e) => count > 0 && index === 0 ? onClick(e, true) : onClick(e, false)}>
            {value}&nbsp;{count > 0 && index === 0 && <ArrowDropDown sx={{ verticalAlign: "middle" }} />}
        </Typography>
    )
}

const Component = (props) => {

    const { selectedProperties, selectedColumns, onMenuSelected, searchFields } = props;
    const [seqNumber, setSeqNumber] = React.useState(null);
    const [fieldId, setFieldId] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [rows, setRows] = React.useState([]);

    const count = selectedProperties ? selectedProperties.length : 0;

    const OnMenuClicked = (e, index, count, fieldId, seqNumber) => {
        e.preventDefault();
        if (count > 0 && index === 0) {
            setFieldId(fieldId);
            setSeqNumber(seqNumber);
            setAnchorEl(e.target);
        }
    }

    const OnMenuClosed = (e) => {
        setAnchorEl(null);
        setSeqNumber(null);
        if (e) {
            const { name, value, seqNumber, index } = e;
            let tmpOrg = rows;
            if (index === -2) {
                const nextCount = Object.keys(tmpOrg[0]).length + 1;
                tmpOrg[0][`Field_${nextCount}`] = "Select Field";
                setRows(tmpOrg);
            } else {
                if (onMenuSelected) onMenuSelected({ name, value: value, options: selectedProperties, seqNumber });
                if (value) {
                    tmpOrg[0][name] = value;
                } else {
                    tmpOrg[0][name] = Samples[0][name];
                }
                setRows(tmpOrg);
            }
        }
    }

    const OnSearchChanged = (e) => {
        if (onMenuSelected) onMenuSelected({ name: "search", value: e });
    }

    React.useEffect(() => {

        const GetSelectedMenuName = (e, v) => {
            if (selectedColumns && selectedColumns[e]) return selectedColumns[e].DisplayName;
            return v;
        }

        let items = [];
        Samples.forEach((elm, index) => {
            let tmp = {};
            for (let e in elm) {
                const tValue = index === 0 ? GetSelectedMenuName(e, elm[e]) : elm[e];
                tmp = { ...tmp, [e]: tValue };
            }
            items.push(tmp);
        });
        setRows(items);
    }, [selectedColumns]);

    return (
        <>
            <List dense={true} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
                    <SearchInput showSelection={count > 0} defaContent="Select Search Field" onSearchChanged={OnSearchChanged}
                        nameId={'Name'} valueId={'Name'} contentId={'Label'} options={searchFields} />
                </Stack>
                {rows && rows.map((x, index) => (
                    <React.Fragment key={index}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                {count > 0 && index === 0 &&
                                    <Typography variant="body2" component="p" sx={{
                                        margin: "2px",
                                        display: 'block', cursor: count > 0 && index === 0 ? "pointer" : "default",
                                        '&:hover': {
                                            border: count > 0 && index === 0 && "1px dashed blue"
                                        }
                                    }}
                                        onClick={(e) => OnMenuClicked(e, index, count, "Field_1", 1)}
                                    >Select Image&nbsp;<ArrowDropDown sx={{ verticalAlign: "middle" }} /></Typography>}
                                <Avatar alt="Travis Howard" src={x.Field_1}><ImageNotSupportedIcon /></Avatar>
                            </ListItemAvatar>
                            <ListItemText sx={{ width: "50%", ml: 1, mr: 1 }}
                                primary={
                                    <RenderMenuItem id="Field_2" variant="cardheader" index={index} value={x.Field_2} count={count}
                                        onClick={(e) => OnMenuClicked(e, index, count, "Field_2", 2)} />
                                }
                                secondary={
                                    <>
                                        <RenderMenuItem id="Field_3" variant="standard" index={index} value={x.Field_3} count={count}
                                            onClick={(e) => OnMenuClicked(e, index, count, "Field_3", 3)} />
                                    </>
                                } />

                            <ListItemText sx={{ width: "50%" }}
                                primary={
                                    <RenderMenuItem id="Field_4" variant="standard" index={index} value={x.Field_4} count={count}
                                        onClick={(e) => OnMenuClicked(e, index, count, "Field_4", 4)} />
                                }
                                secondary={
                                    <>
                                        <RenderMenuItem id="Field_5" variant="standard" index={index} value={x.Field_5} count={count}
                                            onClick={(e) => OnMenuClicked(e, index, count, "Field_5", 5)} />

                                        <RenderMenuItem id={'Field_6'} variant={(x.Field_6 && x.Field_6.toLowerCase()) || "standard"} index={index} value={x.Field_6} count={count}
                                            onClick={(e) => OnMenuClicked(e, index, count, "Field_6", 6)} />
                                    </>
                                } />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                ))}
            </List>
            {anchorEl && <MenuItemList seqNumber={seqNumber} fieldId={fieldId} anchorEl={anchorEl} {...props} onMenuClosed={OnMenuClosed} />}
        </>
    );
}

export default Component;