import * as React from 'react';
import { InputBase, useTheme, MenuItem, Select, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Search as SearchIcon } from '@mui/icons-material';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    marginRight: 2,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    marginRight: 10,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(3)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        borderBottom: theme.borderBottom,
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            '&:focus': {
                borderBottom: theme.borderBottomFocus
            },
        },
    },
}));

const Component = ({ name, value, nameId, valueId, contentId,
    showSelection, defaContent, options, onSearchChanged }) => {

    const theme = useTheme();
    const [selectedValue, setSelectedValue] = React.useState(value || 'NONE');
    let defValues = [{ id: -1, value: 'NONE', content: defaContent || 'Select a column' }];

    const OnInputChanged = (e) => {
        e.preventDefault();
        setSelectedValue(e.target.value);
        let _value = e.target.value;
        if (_value === 'NONE') _value = null;
        onSearchChanged && onSearchChanged(_value);
    }

    React.useEffect(() => {
        let tmp = value || "NONE";
        setSelectedValue(tmp);
    }, [value]);

    return (
        <>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                {showSelection ? (
                    <Box sx={{ width: 225 }}>
                        <Select name={name} value={selectedValue || "NONE"} size="small"
                            onChange={(e) => OnInputChanged(e)}
                            onOpen={(e) => e.stopPropagation()}
                            onBlur={(e) => e.stopPropagation()}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                            MenuProps={{
                                sx: {
                                    "&& .Mui-selected": { ...theme.selected }
                                }
                            }}
                            sx={{ width: "100%", paddingLeft: `calc(1em + ${theme.spacing(1)})`, }}
                        >
                            {defValues.map((x, index) => (
                                <MenuItem key={1000 + index} value={x.value} name={x.name}>
                                    {x.content}
                                </MenuItem>
                            ))}

                            {options && options.map((x, index) => (
                                <MenuItem key={2000 + index} value={x[valueId]} name={x[nameId]}>
                                    {x[contentId]}
                                </MenuItem>
                            ))}

                        </Select>
                    </Box>
                ) : (
                    <StyledInputBase placeholder="Search…" inputprops={{ 'aria-label': 'search' }} />
                )}

            </Search>
        </>
    );
}

export default Component;
